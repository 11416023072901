import { Classifier, ClassifiersList } from '@core/models';
import { TotalDataType } from '@core/types';

export const parseClassifiersHelper = (classifiers: {
  [k: string]: TotalDataType<Classifier[]>;
}): ClassifiersList =>
  Object.keys(classifiers)
    .map((key: string) => ({
      [key]: classifiers[key].data,
    }))
    .reduce((prev, curr) => ({ ...prev, ...curr })) as ClassifiersList;
