import { ActivatedRouteSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { ServicesService } from '@core/services';
import { Observable } from 'rxjs';
import { ServicesList } from '@core/models';
import { ServicesSearchQueryDto } from '@core/models/dto';
import { ClassifierTypes } from '@core/utils';

export const getServicesByTypeAndIdResolver = (
  route: ActivatedRouteSnapshot,
  isNotEmpty: boolean
): Observable<ServicesList> => {
  const type = route.params['type'] as ClassifierTypes;
  const id = route.params['id'] as number;
  const data: ServicesSearchQueryDto = {
    isNotEmpty,
    isEmployeeDepartments: true,
  };
  switch (type) {
    case 'applicantCategories': {
      data.applicantCategoriesIds = [id];
      break;
    }
    case 'lifeSituations': {
      data.lifeSituationsIds = [id];
      break;
    }
    case 'departments': {
      data.departmentsIds = [id];
      break;
    }
    case 'territoriality': {
      data.territorialityIds = [id];
      break;
    }
  }
  return inject(ServicesService).getServices(data);
};
