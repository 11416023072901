import { ChangeDetectionStrategy, Component, EventEmitter, input, OnInit, ViewEncapsulation } from '@angular/core';
import { ChartComponent } from '@core/components/chart/chart.component';
import { Service, ServiceBlock } from '@core/models';
import { ActivatedRouteSnapshot } from '@angular/router';

interface Data {
  service: Service,
  serviceBlocks: ServiceBlock[]
}

@Component({
  selector: 'app-service-chart-modal',
  standalone: true,
  imports: [
    ChartComponent,
  ],
  templateUrl: './service-chart-modal.component.html',
  styleUrl: './service-chart-modal.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceChartModalComponent implements OnInit {
  activatedRouteSnapshot = input.required<ActivatedRouteSnapshot>();
  service = input.required<Service>();
  close = new EventEmitter<void>();
  data: Data = {service: {} as Service, serviceBlocks: [] as ServiceBlock[]};

  ngOnInit() {
    this.data = this.activatedRouteSnapshot().data as Data;
  }

  closeModal() {
    this.close.emit();
  }
}
