import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UserService, SubdivisionsService } from '@core/services';
import { Subdivision } from '@core/models';
import { IconButtonComponent, SearchComponent } from '@core/components';
import { getSubdivisionTypeTranslationsHelper } from '@core/utils';

interface SearchForm {
  name: FormControl<string | null>;
}

@Component({
  selector: 'app-subdivision',
  standalone: true,
  imports: [SearchComponent, IconButtonComponent],
  templateUrl: './subdivision.component.html',
  styleUrl: './subdivision.component.scss',
})
export class SubdivisionComponent {
  public getSubdivisionTypeTranslation = getSubdivisionTypeTranslationsHelper;
  public subdivisions: Subdivision[] = [];
  public isSearching: boolean = false;
  public isSearchBlocked: boolean = false;
  public searchForm = new FormGroup<SearchForm>({
    name: new FormControl('', {
      validators: [Validators.required],
      nonNullable: false,
    }),
  });

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly subdivisionsService: SubdivisionsService,
    private readonly location: Location
  ) {}

  ngOnInit(): void {}

  public handleSearch(): void {
    if (
      this.searchName.value === null ||
      this.searchName.value?.length === 0 ||
      this.isSearchBlocked
    ) {
      this.handleClear();
      return;
    }

    this.isSearchBlocked = true;
    this.subdivisionsService
      .getSubdivisions({ name: this.searchName.value, from: 0, to: 5 })
      .subscribe({
        next: (subdivisions: Subdivision[]) => {
          this.subdivisions = subdivisions;
          this.isSearchBlocked = false;
        },
        error: (err: Error) => {
          console.error(err);
        },
      });
  }

  public handleClear(): void {
    this.subdivisions = [];
  }

  public handleSelectSubdivisionClick(subdivision: Subdivision): void {
    this.userService.selectSubdivision(subdivision.id).subscribe({
      next: () => {
        this.router.navigate(['/'])
        
      },
    });
  }

  get searchName(): FormControl<string> {
    return this.searchForm.controls['name'] as FormControl<string>;
  }

  navigateToPrevious() {
    this.location.back();
  }
}
