<div class="home">
  <div class="home__services">
    <h1>Популярные услуги</h1>
    @if (services.length > 0) {
    <div class="home__services-list">
        @for (serviceList of topServices; track serviceList) {
          <div class="home__services-list-row">
            @for (service of serviceList; track service.id) {
              <div class="home__services-list-block">
                <app-service-block [service]="service"></app-service-block>
              </div>
            }
          </div>
        }
      </div>
      } @else {
        <p class="title-regular-h3 on-container-low">Нет услуг</p>
      }
  </div>

  <div class="home__classifiers">
    <h1>Классификаторы</h1>
    <div class="home__classifiers-content">
      <div class="home__classifiers-content-menu">
        <app-menu-point wrap="nowrap" text="Жизненные ситуации" type="client" [shouldNavigate]="false"
                        icon="assets/icons/life-situations-icon.svg" [isActive]="selectedClassifiersTab === 'lifeSituations'"
                        (click)="handleSelectTab('lifeSituations')"></app-menu-point>
        <app-menu-point wrap="nowrap" text="Категория заявителей" type="client" [shouldNavigate]="false"
                        icon="assets/icons/applicant-category-icon.svg" [isActive]="selectedClassifiersTab === 'applicantCategories'"
                        (click)="handleSelectTab('applicantCategories')"></app-menu-point>
        <app-menu-point wrap="nowrap" text="Подразделения МФЦ" type="client" [shouldNavigate]="false"
                        icon="assets/icons/departments-icon.svg" [isActive]="selectedClassifiersTab === 'departments'"
                        (click)="handleSelectTab('departments')"></app-menu-point>
        <app-menu-point wrap="nowrap" text="Территориальность" type="client" [shouldNavigate]="false"
                        icon="assets/icons/territoriality-icon.svg" [isActive]="selectedClassifiersTab === 'territoriality'"
                        (click)="handleSelectTab('territoriality')"></app-menu-point>
        <app-menu-point wrap="nowrap" text="Ведомства" type="client" [shouldNavigate]="false"
                        icon="assets/icons/departments-icon.svg" [isActive]="selectedClassifiersTab === 'divisions'"
                        (click)="handleSelectTab('divisions')"></app-menu-point>
      </div>
      <div class="home__classifiers-content-list">
        <div class="home__classifiers-content-list-body">
          @if (classifiers[selectedClassifiersTab].length > 0) {
            @for (cont of classifiers[selectedClassifiersTab]; track cont) {
              <a [routerLink]="'/'+ selectedClassifiersTab + '/' + cont.id">
                <div class="home__classifiers-content-list-element">
                  {{cont.name}}
                </div>
              </a>
            }
          }
          @else {
            <p>Нет классификаторов</p>
          }
        </div>
        @if(!isAllClassifiersLoaded.get(selectedClassifiersTab)){
          <app-button size="normal"
                      type="left"
                      icon="add_circle"
                      [fill]="true"
                      display="fill"
                      color="container-lowest"
                      sizeText="default"
                      buttonContent="Показать больше"
                      (click)="loadMoreClassifiers(selectedClassifiersTab)">
          </app-button>
        }
      </div>
    </div>
  </div>
</div>
