import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';

import { authGuard, loginGuard } from '@core/guards';
import { NotFoundComponent } from '@core/components';
import { getServicesByTypeAndIdResolver } from '@core/resolvers';

import { LoginComponent } from '@scripter/app/login/login.component';
import { HomeComponent } from '@scripter/app/home/home.component';
import { ServicesService, ClassifiersService } from '@core/services';
import { ServicesListComponent } from '@scripter/app/home/services-list/services-list.component';
import { ServiceComponent } from '@scripter/app/service/service.component';
import { SubdivisionComponent } from '@scripter/app/subdivision/subdivision.component';
import { OverlayClientComponent } from '@core/components/overlay/overlay-new/overlay-client/overlay-client.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard('/')],
  },
  {
    path: '',
    component: OverlayClientComponent, //overlayComponent
    children: [
      {
        path: 'services/:id',
        component: ServiceComponent,
        canActivate: [authGuard],
        resolve: {
          service: (route: ActivatedRouteSnapshot) =>
            inject(ServicesService).getServiceById(route.params['id']),
          serviceBlocks: (route: ActivatedRouteSnapshot) =>
            inject(ServicesService).getServiceBlocks(route.params['id']),
        },
      },
      {
        path: '',
        children: [
          {
            path: '',
            component: HomeComponent,
            canActivate: [authGuard],
            resolve: {
              services: () =>
                inject(ServicesService).getServices({
                  isNotEmpty: true,
                  isEmployeeDepartments: true,
                  from: 0,
                  to: 9
                }),
              classifiers: () => inject(ClassifiersService).getTopClassifiers(6),
            },
          },
          {
            path: ':type/:id',
            component: ServicesListComponent,
            canActivate: [authGuard],
            resolve: {
              classifier: ({ params }: ActivatedRouteSnapshot) => inject(ClassifiersService).getOneClassifier(params["type"], params["id"]),
              services: (route: ActivatedRouteSnapshot) => getServicesByTypeAndIdResolver(route, true),
            },
          },
        ],
      },
      {
        path: '',
        children: [
          {
            path: 'subdivision',
            component: SubdivisionComponent,
            canActivate: [authGuard],
          },
        ],
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];
