<div class="services">
  <h3 class="services__type-title">
    <div class="back">
      <app-icon-button
        size="normal"
        type="square"
        icon="undo"
        display="overlay"
        color="cell"
        (click)="navigateToPrevious()">
      </app-icon-button>
    </div>
    {{ selectedClassifierTypeTitle }}
  </h3>
  <h1 class="services__title">{{ selectedClassifierTitle }}</h1>

  @if (services.length > 0) {
    <div class="services__list">
      @for (grid of gridServices; track grid) {
        <div class="services__list-col">
          @for (cont of grid; track cont) {
            <div class="services__list-element">
              <app-service-block [service]="cont"></app-service-block>
            </div>
          }
        </div>
      }
    </div>
  } @else {
    <p class="services__title title-regular-h3 on-container-low">Нет услуг по Вашему запросу</p>
  }
</div>
