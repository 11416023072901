@if (service) {
  <a [routerLink]="'/services/' + service.id">
    <div class="service">
      <!-- <div class="service__icons">
        <img src="assets/icons/list-icon.svg" alt="#" class="service__list-icon">
        <button class="service__icons-button button-base"></button>
      </div> -->
      <p class="service__title">
        {{service.name}} <span>({{getServiceTypeTranslation(service.type)}})</span>
      </p>
      <div class="service__badges">
        @for (badge of Array.from(service.territoriality); track badge.id) {
          <div class="service__badges-item">
            <app-badge size="medium" display="tint" color="primary-container" size_text="default"
                       badge_content="{{badge.name}}"></app-badge>
          </div>
        }
        @for (badge of Array.from(service.lifeSituations); track badge.id) {
          <div class="service__badges-item">
            <app-badge size="normal" display="tint" color="secondary-container" size_text="default"
                       badge_content="{{badge.name}}"></app-badge>
          </div>
        }
        @for (badge of Array.from(service.departments); track badge.id) {
          <div class="service__badges-item">
            <app-badge size="normal" display="tint" color="primary" size_text="default"
                       badge_content="{{badge.name}}"></app-badge>
          </div>
        }
        @for (badge of Array.from(service.applicantCategories); track badge.id) {
          <div class="service__badges-item">
            <app-badge size="normal" display="tint" color="secondary" size_text="default"
                       badge_content="{{badge.name}}"></app-badge>
          </div>
        }
      </div>
    </div>
  </a>
}
