import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';

import {
  ButtonComponent,
  MenuPointComponent,
  ModalComponent,
} from '@core/components';
import { ClassifiersList, Service } from '@core/models';
import { ClassifiersService, ServicesService, UserService } from '@core/services';
import { ClassifierTypes, parseClassifiersHelper } from '@core/utils';
import { ServiceBlockComponent } from '@scripter/app/home/service-block/service-block.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    ServiceBlockComponent,
    MenuPointComponent,
    RouterLink,
    ButtonComponent,
    ModalComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  services: Service[] = [];
  classifiers: ClassifiersList = {
    applicantCategories: [],
    lifeSituations: [],
    departments: [],
    territoriality: [],
  };
  selectedClassifiersTab: ClassifierTypes = 'lifeSituations';

  isLoadingClassifiers = false;
  isAllClassifiersLoaded = new Map<string, boolean>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly classifiersService: ClassifiersService,
    private readonly cdr: ChangeDetectorRef,
    private readonly userService: UserService,
    private readonly servicesService: ServicesService
  ) {}

  get topServices(): Service[][] {
    return this.services.slice(0, 9)
      .reduce((columns, service, index) => {
        const columnIndex = index % 3;
        if(columns[columnIndex]){
          columns[columnIndex].push(service);
        } else {
          columns[columnIndex] = [service];
        }
        return columns;
      }, new Array<Service[]>());
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ services, classifiers }) => {
      this.services = services.active;
      this.classifiers = parseClassifiersHelper(classifiers);
      for (let type in classifiers) {
        if (classifiers[type].data.length === classifiers[type].total) {
          this.isAllClassifiersLoaded.set(type, true);
        }
      }
    });

    this.userService.currentUserSubject.subscribe((user) => {
      if(user){
        this.loadPopularServices();
      }
    });
  }

  loadPopularServices(){
    this.servicesService.getServices({ isNotEmpty: true, isEmployeeDepartments: true, from: 0, to: 9 })
      .subscribe((services) => this.services = services.active);
  }

  ngOnDestroy(): void {}

  handleSelectTab(selectedClassifiersTab: ClassifierTypes) {
    this.selectedClassifiersTab = selectedClassifiersTab;
  }

  loadMoreClassifiers(type: ClassifierTypes, amount = 6) {
    if (this.isLoadingClassifiers) return;
    this.isLoadingClassifiers = true;
    const from = this.classifiers[type].length;
    this.classifiersService
      .getClassifiers({
        [type]: { from, to: from + amount },
      })
      .subscribe({
        next: result => {
          this.classifiers[type].push(...result[type].data);
          if (this.classifiers[type].length === result[type].total) {
            this.isAllClassifiersLoaded.set(type, true);
          }
          this.isLoadingClassifiers = false;
          this.cdr.markForCheck();
        },
      });
  }
}
