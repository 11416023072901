<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      {{ service().name }}
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <app-chart
    [modalInput]="data"
    [modalVersion]="true"
  ></app-chart>
</div>
