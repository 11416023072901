import { Component, Input } from '@angular/core';
import { Service } from '@core/models';
import { BadgeComponent } from '@core/components';
import { RouterLink } from '@angular/router';
import { getServiceTypeTranslation } from '@core/utils';

@Component({
  selector: 'app-service-block',
  standalone: true,
  imports: [BadgeComponent, RouterLink],
  templateUrl: './service-block.component.html',
  styleUrl: './service-block.component.scss',
})
export class ServiceBlockComponent {
  @Input() service!: Service;
  protected readonly Array = Array;
  protected readonly getServiceTypeTranslation = getServiceTypeTranslation;
}
