<div class="wysiwyg" [ngClass]="{'wysiwyg_expanded': expandMode}">
    <div class="wysiwyg__toolbar">
        <button class="wysiwyg__toolbar-btn" type="button" (click)="toggleExpand()">
            <span class="material-symbols-outlined">
                {{ expandMode ? 'keyboard_backspace' : 'expand_content' }}
            </span>
        </button>
        <button class="wysiwyg__toolbar-btn" type="button" (click)="undo()">
            <span class="material-symbols-outlined">undo</span>
        </button>
        <button class="wysiwyg__toolbar-btn" type="button" (click)="redo()">
            <span class="material-symbols-outlined">redo</span>
        </button>
        <div class="wysiwyg__toolbar-select" (click)="isTextStyleVariantsOpen = !isTextStyleVariantsOpen">
            <button class="wysiwyg__toolbar-btn" type="button" style="width: 164px;">
                <span>{{ selectedStyleVariant }}</span>
                <span class="material-symbols-outlined">{{ isTextStyleVariantsOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
            </button>
            @if (isTextStyleVariantsOpen) {
            <div class="wysiwyg__toolbar-options">
                @for (variant of textStyleVariants; track variant) {
                    @if (variant !== selectedStyleVariant) {
                        <button class="wysiwyg__toolbar-btn" type="button" (click)="handleSelectTextStyle(variant)">
                            {{ variant }}
                        </button>
                    }
                }
            </div>
            }
        </div>
        <div class="wysiwyg__toolbar-select"  (click)="isTextAlignVariantsOpen = !isTextAlignVariantsOpen">
            <button class="wysiwyg__toolbar-btn" type="button">
                <span class="material-symbols-outlined">{{ selectedTextAlign }}</span>
                <span class="material-symbols-outlined">{{ isTextAlignVariantsOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
            </button>
            @if (isTextAlignVariantsOpen) {
            <div class="wysiwyg__toolbar-options">
                @for (variant of textAlignVariants; track variant) {
                    @if (variant !== selectedTextAlign) {
                        <button class="wysiwyg__toolbar-btn" type="button" (click)="handleSelectTextAlign(variant)">
                            <span class="material-symbols-outlined">{{variant}}</span>
                        </button>
                    }
                }
            </div>
            }
        </div>
        <button class="wysiwyg__toolbar-btn" type="button" (click)="formatBold()" [ngClass]="{'wysiwyg__toolbar-btn_active': bold}">
            <span class="material-symbols-outlined">format_bold</span>
        </button>
        <button class="wysiwyg__toolbar-btn" type="button" (click)="formatItalic()" [ngClass]="{'wysiwyg__toolbar-btn_active': italic}">
            <span class="material-symbols-outlined">format_italic</span>
        </button>
        <button class="wysiwyg__toolbar-btn" type="button" (click)="formatUnderlined()" [ngClass]="{'wysiwyg__toolbar-btn_active': underline}">
            <span class="material-symbols-outlined">format_underlined</span>
        </button>
        <button class="wysiwyg__toolbar-btn" type="button" (click)="formatQuote()">
            <span class="material-symbols-outlined">format_quote</span>
        </button>
        <button class="wysiwyg__toolbar-btn" type="button" (click)="formatListBulleted()">
            <span class="material-symbols-outlined">format_list_bulleted</span>
        </button>
        <button class="wysiwyg__toolbar-btn" type="button" (click)="formatListNumbered()">
            <span class="material-symbols-outlined">format_list_numbered</span>
        </button>
    </div>

    <p class="wysiwyg__heading title-bold-h3">{{heading}}</p>

    <section class="wysiwyg__content text-bigger" 
        id="section" 
        contenteditable="true" 
        [innerHTML]="value"
        (input)="change($event)"
        (focus)="handleFocus()"
        (focusout)="handleUnfocus()"
    ></section>
</div>
