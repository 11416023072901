import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Classifier, Service, ServicesList } from '@core/models';
import { BadgeComponent, IconButtonComponent } from '@core/components';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ClassifierTypes, shouldLoadDataHelper } from '@core/utils';
import { ServiceBlockComponent } from '@scripter/app/home/service-block/service-block.component';
import { ServicesService } from '@core/services';

@Component({
  selector: 'app-services-list',
  standalone: true,
  imports: [BadgeComponent, ServiceBlockComponent, IconButtonComponent],
  templateUrl: './services-list.component.html',
  styleUrl: './services-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesListComponent implements OnInit {
  id: number | null = null;
  type: ClassifierTypes = 'applicantCategories';
  classifier: Classifier | undefined;
  services: Service[] = [];
  gridServices: Service[][] = [[]];

  get selectedClassifierTypeTitle(): string {
    switch (this.type) {
      case 'applicantCategories':
        return 'Категории заявителей';
      case 'lifeSituations':
        return 'Жизненные ситуации';
      case 'departments':
        return 'Подразделения МФЦ';
      case 'territoriality':
        return 'Территориальность';
      default:
        return '';
    }
  }

  get selectedClassifierTitle(): string {
    return this.classifier?.name || '';
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly servicesService: ServicesService,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.type = params['type'];
    });
    this.activatedRoute.data.subscribe(data => {
      this.classifier = data['classifier'];
      this.services = data['services']?.active;
      this.GetGridServices();
    });
  }

  private isLoaded: boolean = false;
  private isFullData: boolean = false;
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (shouldLoadDataHelper() && !this.isLoaded && !this.isFullData) {
      this.isLoaded = true;
      const typeSelector = [this.type] + 'Ids';

      this.servicesService
        .getServices({
          [typeSelector]: [this.id],
          from: this.services.length,
          to: this.services.length + 5,
        })
        .subscribe({
          next: (servicesList: ServicesList) => {
            if (servicesList.active.length === 0) {
              this.isFullData = true;
            } else {
              this.services = this.services.concat(servicesList.active);
            }
            this.isLoaded = false;
          },
        });
    }
  }

  GetGridServices() {
    const result: Service[][] = [[], [], []];
    let iterator = 0;
    for (const service of this.services) {
      result[iterator].push(service);
      iterator++;
      if (iterator === 3) {
        iterator = 0;
      }
    }
    this.gridServices = result;
  }

  navigateToPrevious() {
    this.location.back();
  }
}
