import { ChangeDetectionStrategy, Component, EventEmitter, input, ViewEncapsulation } from '@angular/core';
import { MenuPointComponent } from '@core/components';
import { Service } from '@core/models';

@Component({
  selector: 'app-service-info-modal',
  standalone: true,
  imports: [
    MenuPointComponent,
  ],
  templateUrl: './service-info-modal.component.html',
  styleUrl: './service-info-modal.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceInfoModalComponent {
  service = input.required<Service>();
  close = new EventEmitter<void>();
  activeArray: boolean[] = [
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  handleMenuItemClick(count: number) {
    this.activeArray = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    this.activeArray[count] = true;
  }

  closeModal() {
    this.close.emit();
  }
}
