<div class="app-modal-container">
  <div class='app-modal-container__header'>
    <h4 class="app-modal-container__title title-bold-h4">
      {{ service().name }}
    </h4>
    <button class="app-modal-container__button" (click)="closeModal()">
      <span class="app-modal-container__close material-symbols-outlined">close</span>
    </button>
  </div>
  <form
    class="app-modal-container__form"
  >
    <div class="app-modal-container__body">
      <div class="modal">
        <div class="ref-info">
          <nav class="left-navigation left-navigation_wysiwyg">
            <app-menu-point
              text="Общее описание"
              [shouldNavigate]="false"
              [isActive]="activeArray[0]"
              (click)="handleMenuItemClick(0)"></app-menu-point>
            <app-menu-point
              text="Порядок действий"
              [shouldNavigate]="false"
              [isActive]="activeArray[1]"
              (click)="handleMenuItemClick(1)"></app-menu-point>
            <app-menu-point
              text="Способ передачи заявления и документов"
              [shouldNavigate]="false"
              [isActive]="activeArray[2]"
              (click)="handleMenuItemClick(2)"></app-menu-point>
            <app-menu-point
              text="Уровень предоставления"
              [shouldNavigate]="false"
              [isActive]="activeArray[3]"
              (click)="handleMenuItemClick(3)"></app-menu-point>
            <app-menu-point
              text="Категория заявителей"
              [shouldNavigate]="false"
              [isActive]="activeArray[4]"
              (click)="handleMenuItemClick(4)"></app-menu-point>
            <app-menu-point
              text="Гражданство заявителя"
              [shouldNavigate]="false"
              [isActive]="activeArray[5]"
              (click)="handleMenuItemClick(5)"></app-menu-point>
            <app-menu-point
              text="Требование проживания"
              [shouldNavigate]="false"
              [isActive]="activeArray[6]"
              (click)="handleMenuItemClick(6)"></app-menu-point>
            <app-menu-point
              text="Результат приема заявления"
              [shouldNavigate]="false"
              [isActive]="activeArray[7]"
              (click)="handleMenuItemClick(7)"></app-menu-point>
            <app-menu-point
              text="Результат предоставления услуги"
              [shouldNavigate]="false"
              [isActive]="activeArray[8]"
              (click)="handleMenuItemClick(8)"></app-menu-point>
            <app-menu-point
              text="Поступление результата в МФЦ"
              [shouldNavigate]="false"
              [isActive]="activeArray[10]"
              (click)="handleMenuItemClick(10)"></app-menu-point>
            <app-menu-point
              text="Дополнительные сведения"
              [shouldNavigate]="false"
              [isActive]="activeArray[11]"
              (click)="handleMenuItemClick(11)"></app-menu-point>
            <app-menu-point
              text="Срок предоставления"
              [shouldNavigate]="false"
              [isActive]="activeArray[12]"
              (click)="handleMenuItemClick(12)"></app-menu-point>
            <app-menu-point
              text="Список документов"
              [shouldNavigate]="false"
              [isActive]="activeArray[13]"
              (click)="handleMenuItemClick(13)"></app-menu-point>
          </nav>
          <div class="texts-container texts-container_wysiwyg">
            @if (activeArray[0]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('information')"
      
              ></div>
            }
            @if (activeArray[1]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationProcedure')"
      
              ></div>
            }
            @if (activeArray[2]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationTransferMethod')"
      
              ></div>
            }
            @if (activeArray[3]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationProvisionMethod')"
      
              ></div>
            }
            @if (activeArray[4]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationApplicantCategory')"
      
              ></div>
            }
            @if (activeArray[5]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationApplicantNationality')"
      
              ></div>
            }
            @if (activeArray[6]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationResidenceRequirement')"
      
              ></div>
            }
            @if (activeArray[7]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationApplicationResult')"
      
              ></div>
            }
            @if (activeArray[8]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationServiceResult')"
      
              ></div>
            }
            @if (activeArray[10]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationReceiptOfTheResult')"
      
              ></div>
            }
            @if (activeArray[11]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationAdditional')"
      
              ></div>
            }
            @if (activeArray[12]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationTermOfService')"
              ></div>
            }
            @if (activeArray[13]) {
              <div
                class="texts-container-wysiwyg"
                [innerHTML]="getInformationHTML('informationListOfDocuments')"
              ></div>
            }
          </div>
        </div>
      </div>
    </div>
  </form>
</div>