import { Component, DestroyRef, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  ButtonComponent,
  EmailInputComponent,
  IconButtonComponent,
  ModalComponent,
  PasswordInputComponent,
  TextInputComponent,
} from '@core/components';
import { UserService } from '@core/services';
import { DateInputComponent } from '@core/components';

interface LoginForm {
  login: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    ModalComponent,
    ReactiveFormsModule,
    RouterLink,
    EmailInputComponent,
    PasswordInputComponent,
    DateInputComponent,
    TextInputComponent,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  destroyRef = inject(DestroyRef);
  error: string = ' ';
  isSubmitted = false;
  loginForm: FormGroup;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router
  ) {
    this.loginForm = new FormGroup<LoginForm>({
      login: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      password: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    });
  }

  onFormSubmit() {
    if (
      this.loginForm.value['login'].length === 0 ||
      this.loginForm.value['password'].length === 0
    ) {
      this.error = 'Поля не должны быть пустыми';
      this.loginForm.reset();
      return;
    }
    this.isSubmitted = true;

    this.userService.login(this.loginForm.value)
      .subscribe({
        next: () => {
          this.router.navigate(['/']);
          this.isSubmitted = false;

        },
        error: () => {
          this.error = 'Неправильные данные для входа';
          this.isSubmitted = false;
          this.loginForm.reset();
        },
      });
  }
}
