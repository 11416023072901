import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BadgeComponent, ButtonComponent, IconButtonComponent, InputComponent, ModalComponent } from '@core/components';
import { Attachment, Document, Service, ServiceBlock, ServiceBlockVariantDocument } from '@core/models';
import { ModalService } from '@core/services';
import { PresendedFormEnum, PresendedPackEnum, ServiceBlockTypes } from '@core/utils';
import { ChartComponent } from '@core/components/chart/chart.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Overlay } from '@angular/cdk/overlay';
import { openModal } from '@scripter-admin/app/modals/open-modal';
import {
  ServiceInfoModalComponent
} from '@scripter-admin/app/modals/client-side-modals/service-info-modal/service-info-modal.component';
import {
  ServiceChartModalComponent
} from '@scripter-admin/app/modals/client-side-modals/service-chart-modal/service-chart-modal.component';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-service',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    BadgeComponent,
    ButtonComponent,
    ModalComponent,
    FormsModule,
    IconButtonComponent,
    InputComponent,
    ChartComponent,
  ],
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceComponent implements OnInit {
  private overlay = inject(Overlay);
  service: Service | null = null;
  serviceBlocks = new Map<string, ServiceBlock>();
  rootBlock: ServiceBlock | null = null;
  currentBlock = this.rootBlock;
  history = new Array<ServiceBlock>();
  documents: ServiceBlockVariantDocument[] = [];
  checkedDocuments: ServiceBlockVariantDocument[] = [];
  isChartOpen: boolean = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ service, serviceBlocks }) => {
      this.service = service;
      this.setServiceBlocks(serviceBlocks);
      if(this.rootBlock){
        this.moveToNextBlock(this.rootBlock.clientId);
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  detectRefresh(event: Event) {
    if (this.currentBlock !== this.rootBlock) {
      event.preventDefault();
    }
  }

  @HostListener('window:location', ['$event'])
  detectLeave(event: Event) {
    if (this.currentBlock !== this.rootBlock) {
      event.preventDefault();
    }
  }

  get childrenBlocks(): ServiceBlock[] {
    return this.currentBlock?.childIds.map((id) => this.serviceBlocks.get(id)!) || [];
  }

  get listOfDocuments() {
    const result = [];
    result.push({
      text: `Список документов для "${this.service?.title}":`,
      style: 'header',
    });
    result.push({ ol: this.documents.map(d => d.title) });
    return result;
  }

  get allDocuments() {
    const allDocuments: any = [];
    allDocuments.push({
      text: `Список документов для "${this.service?.title}"`,
      style: 'header',
    });
    [...this.serviceBlocks.values()].forEach(block => {
      if (block.documents && block.documents.length > 0) {
        allDocuments.push({
          text: `Для ответа "${block.title}" ${block.documents.length > 1 ? 'требуются следующие документы: ' : 'требуется документ: '}`,
          style: 'header',
        });
        allDocuments.push({
          ol: block.documents.map(d => d.title),
        });
      }
    });

    return allDocuments;
  }

  getDocumentAttachment(doc: ServiceBlockVariantDocument){
    return doc.documentTemplate?.documentsTemplatesAttachments[0];
  }

  getPresendedFormTypeTranslation(formType: PresendedFormEnum): string {
    switch (formType) {
      case PresendedFormEnum.Original:
        return 'Оригинал';
      case PresendedFormEnum.Copy:
        return 'Копия';
      case PresendedFormEnum.CertifiedCopy:
        return 'Заверенная копия';
      case PresendedFormEnum.Information:
        return 'Информация';
    }
  }

  getPresendedPackTypeTranslation(packType: PresendedPackEnum): string {
    switch (packType) {
      case PresendedPackEnum.Original:
        return 'Оригинал';
      case PresendedPackEnum.Scan:
        return 'Скан';
      case PresendedPackEnum.NotIncluded:
        return 'Не представлено';
    }
  }

  setServiceBlocks(blocks: ServiceBlock[]){
    this.serviceBlocks.clear();
    blocks.forEach((immutableBlock) => {
      const block = {...immutableBlock};
      this.serviceBlocks.set(block.clientId, block);
      if(block.type === ServiceBlockTypes.Template){
        this.setTemplateBlocks(block);
      }
      if(!this.rootBlock || block.level < this.rootBlock.level){
        this.rootBlock = block;
      }
    });
  }

  setTemplateBlocks(serviceBlock: ServiceBlock){
    let templateRoot: ServiceBlock;
    serviceBlock.template?.templateBlocks.forEach((immutableBlock) => {
      const templateBlock = {...immutableBlock};
      this.serviceBlocks.set(templateBlock.clientId, templateBlock);
      if(templateBlock.type === ServiceBlockTypes.Template){
        this.setTemplateBlocks(templateBlock);
      }
      if(!templateRoot || templateBlock.level < templateRoot.level){
        templateRoot = templateBlock;
      }
      if(templateBlock.type !== ServiceBlockTypes.Closed && !templateBlock.childIds.length){
        templateBlock.childIds = serviceBlock.childIds;
      }
    });
    if(templateRoot!){
      serviceBlock.childIds = [templateRoot.clientId];
    }
  }

  checkDocument(id: number) {
    const checked = this.checkedDocuments?.find((d: Document) => d.id === id);
    if (checked) {
      this.checkedDocuments = this.checkedDocuments.filter(d => d.id !== id);
    } else {
      this.checkedDocuments.push(
        this.documents.find(
          d => d.id === id
        ) as unknown as ServiceBlockVariantDocument
      );
    }
  }

  isCheckedDocument(id: number): boolean {
    return !!this.checkedDocuments.find((d: Document) => d.id === id);
  }

  handleShowDocumentAttachment(attachment: Attachment): void {
    window.open(attachment.link, "_blank");
  }

  generateAllDocuments() {
    let pdfDefinition = {
      content: this.allDocuments,
    };
    pdfMake.createPdf(pdfDefinition).open();
  }

  generatePdf() {
    let pdfDefinition = {
      content: this.listOfDocuments,
    };
    pdfMake.createPdf(pdfDefinition).open();
  }

  downloadServiceDocumentsList(): void {
    let pdfDefinition = {
      content: this.listOfDocuments,
    };
    pdfMake.createPdf(pdfDefinition).download(this.service?.title ?? 'Услуга');
  }

  moveToNextBlock(id: string): void {
    const nextBlock = this.serviceBlocks.get(id);
    if(!nextBlock) return;

    if(this.currentBlock){
      this.history.push(this.currentBlock!);
    }
    this.currentBlock = nextBlock;

    if(this.currentBlock.type === ServiceBlockTypes.Answer) {
      if (this.currentBlock.documents?.length) {
        this.documents = this.documents.concat(this.currentBlock.documents);
      }
      this.history.push(this.currentBlock);
      this.currentBlock = this.serviceBlocks.get(this.currentBlock.childIds[0])!;
    }

    if(this.currentBlock.type === ServiceBlockTypes.Template){
      this.currentBlock = this.serviceBlocks.get(this.currentBlock.childIds[0])!;
    }

    if(this.currentBlock.type === ServiceBlockTypes.Result && this.currentBlock.childIds.length){
      this.currentBlock = this.serviceBlocks.get(this.currentBlock.childIds[0])!;
    }
  }

  moveToPreviousBlock(): void {
    if(this.history.length){
      this.currentBlock = this.history.pop()!;
      if (this.currentBlock.type === ServiceBlockTypes.Answer) {
        if (this.currentBlock.documents?.length) {
          this.documents = this.documents.filter(
            (doc: ServiceBlockVariantDocument) => this.currentBlock!.documents!.includes(doc)
          );
        }
        this.currentBlock = this.history.pop()!;
      }
    }
  }

  openChart() {
    this.modalService.open('chart-modal');
    this.isChartOpen = true;
  }

  openInfoModal() {
    const modal = openModal(
      this.overlay,
      ServiceInfoModalComponent,
      {
        service: this.service,
      }
    )
  }

  openChartModal() {
    const modal = openModal(
      this.overlay,
      ServiceChartModalComponent,
      {
        service: this.service,
        activatedRouteSnapshot: this.activatedRoute.snapshot,
      }
    )
  }

  protected readonly ServiceBlockTypes = ServiceBlockTypes;
}
