<div class="service-module">
  <div class="service-module__block">
    <div class="service-module__block-title">
      <app-button
        size="normal"
        iconSize="large"
        display="inverse"
        type="right"
        icon="arrow_left_alt"
        color="container-lowest"
        sizeText="small"
        [usedBadge]="false"
        buttonContent=""
        typing="submit"
        [routerLink]="'/'" />

      <div class="service-module__block-title-header">
        {{ service?.name }}
      </div>

      <div class="service-module__block-title-buttons">
        <app-button
          size="smallest"
          type="left"
          display="inverse"
          color="inverse-container"
          sizeText="default"
          icon="print"
          buttonContent="Печать"
          (click)="generateAllDocuments()"></app-button>
        <app-button
          size="smallest"
          type="left"
          display="inverse"
          color="inverse-container"
          sizeText="default"
          icon="explore"
          buttonContent="Схема"
          (click)="openChartModal()"></app-button>
        <app-button
          size="smallest"
          type="left"
          display="inverse"
          color="inverse-container"
          sizeText="default"
          icon="help"
          buttonContent="Справка"
          class="moz-fix"
          (click)="openInfoModal()"></app-button>
        <app-button
          size="smallest"
          type="no"
          display="outline"
          color="outline-low"
          sizeText="default"
          buttonContent="Закрыть"
          [routerLink]="'/'"></app-button>
      </div>
    </div>

    <div class="service-module__block-content">

      @if (currentBlock?.type === 'Result') {
        <div class="service-module__block-content-final">
          <div
            *ngIf="history.length > 0"
            class="service-module__block-content-final-back"
            (click)="moveToPreviousBlock()">
            <app-icon-button
              size="normal"
              type="square"
              icon="undo"
              display="overlay"
              color="cell"></app-icon-button>
              Шаг назад
          </div>
          <div class="service-module__block-content-final-header">
            <h1>Перечень документов</h1>
            <div class="service-module__block-content-final-header-buttons">
              <div class="download">
                <app-icon-button
                  size="normal"
                  color="cell"
                  display="fill"
                  type="square"
                  icon="file_download"
                  (click)="downloadServiceDocumentsList()"></app-icon-button>
              </div>
              <div class="print">
                <app-icon-button
                  size="normal"
                  color="cell"
                  display="fill"
                  type="square"
                  icon="print"
                  (click)="generatePdf()"></app-icon-button>
              </div>
            </div>
          </div>
          @if(service?.documentsTransferMethods?.length){
            <p class="transfer-methods">Способы передачи документов: {{ documentsTransferMethods }}.</p>
          }

          <h5>
            Cписок документов, которые нужно запросить у заявителя в зависимости
            от ответов на вопросы. У каждого документа указано в каком виде
            нужно его приложить к пакету документов.
          </h5>
          @for (document of documents; track document) {
            <div
              class="service-module__block-content-final-document {{
                isCheckedDocument(document.id) ? 'document-active' : ''
              }}">
              <div class="service-module__block-content-final-document-left">
                <div
                  class="service-module__block-content-final-document-checkbox">
                  <app-input
                    type="checkbox"
                    labelVersion="regular"
                    (changeEvent)="checkDocument(document.id)"></app-input>
                </div>
              </div>
              <div class="service-module__block-content-final-document-center">
                <div class="service-module__block-content-final-document-title">
                  {{ document.title }}
                </div>
                <div
                  class="service-module__block-content-final-document-badges">
                  <app-badge
                    size="normal"
                    display="outline"
                    color="outline-low"
                    size_text="default"
                    badge_content="Проверка: {{
                      getPresendedFormTypeTranslation(
                        document.presendedFormType
                      )
                    }}"></app-badge>
                  <app-badge
                    size="normal"
                    display="outline"
                    color="outline-low"
                    size_text="default"
                    badge_content="В пакет: {{
                      getPresendedPackTypeTranslation(
                        document.presendedPackType
                      )
                    }}"></app-badge>
                </div>
                <div
                  class="service-module__block-content-final-document-description">
                  {{ document.note }}
                </div>
              </div>
              <div class="service-module__block-content-final-document-right">
                @if (getDocumentAttachment(document)){
                  <app-icon-button
                    size="small"
                    type="square"
                    display="outline"
                    icon="visibility"
                    color="cell"
                    (click)="handleShowDocumentAttachment(getDocumentAttachment(document))"
                  ></app-icon-button>
                }
              </div>
            </div>
          }
        </div>
      } @else {
        <div
          *ngIf="history.length > 0"
          class="service-module__block-content-back"
          (click)="moveToPreviousBlock()">
          <app-icon-button
            size="normal"
            type="square"
            icon="undo"
            display="overlay"
            color="cell"></app-icon-button>
            Шаг назад
        </div>
        <div class="service-module__block-content-block">
          <div class="service-module__block-content-block-iterator">
            <div></div>
            <div class="service-module__block-content-block-iterator-container">
              @if (serviceBlocks.size == 0) {
                <h3>Эта услуга ещё не описана</h3>
                <p>Схема предоставления этой услуги ещё не описана.</p>
              }
              <h3>{{ currentBlock?.title }}</h3>
              <p *ngIf="currentBlock?.note">{{ currentBlock?.note }}</p>
              @if (currentBlock?.type === ServiceBlockTypes.Intermediate) {
                <div
                  class="service-module__block-content-block-buttons-intermediate-list">
                  @for (result of childrenBlocks; track result) {
                    <div
                      class="service-module__block-content-block-buttons-intermediate-list-button"
                      (click)="moveToNextBlock(result.clientId)">
                      {{ result.title }}
                    </div>
                  }
                </div>
              }
              <div class="service-module__block-content-block-buttons">
                @if (currentBlock?.type === ServiceBlockTypes.Result) {
                  <div
                    class="service-module__block-content-block-buttons-intermediate">
                    @for (result of childrenBlocks; track result) {
                      @if (result.type === ServiceBlockTypes.Closed) {
                        <app-button
                          size="small"
                          type="left"
                          display="outline"
                          color="outline-low"
                          sizeText="default"
                          icon="cancel"
                          buttonContent="{{ result.title }}"
                          (click)="moveToNextBlock(result.clientId)"></app-button>
                      } @else if (result.type === ServiceBlockTypes.Result) {
                        <app-button
                          size="small"
                          type="left"
                          display="inverse"
                          color="inverse-container"
                          sizeText="default"
                          icon="check"
                          buttonContent="{{ result.title }}"
                          class="moz-fix"
                          (click)="moveToNextBlock(result.clientId)"></app-button>
                      }
                    }
                  </div>
                } @else {
                  @for (result of childrenBlocks; track result) {
                    @if (result.type === ServiceBlockTypes.Closed) {
                      <app-button
                        size="small"
                        type="left"
                        display="outline"
                        color="outline-low"
                        sizeText="default"
                        icon="cancel"
                        buttonContent="{{ result.title }}"
                        (click)="moveToNextBlock(result.clientId)"></app-button>
                    } @else if (result.type === ServiceBlockTypes.Result) {
                      <app-button
                        size="small"
                        type="left"
                        display="inverse"
                        color="inverse-container"
                        sizeText="default"
                        icon="check"
                        buttonContent="{{ result.title }}"
                        class="moz-fix"
                        (click)="moveToNextBlock(result.clientId)"></app-button>
                    }
                  }
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>

<app-modal
  id="chart-modal"
  width="large"
  bodyPadding="0"
  [heading]="service ? service.title : ''"
  [isButtons]="false">
  @if (isChartOpen) {
    <app-chart [modalVersion]="true"></app-chart>
  }
</app-modal>
