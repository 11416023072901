import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ClientTopMenuComponent } from '@core/components/overlay/client-top-menu/client-top-menu.component';

@Component({
  selector: 'core-overlay-client',
  standalone: true,
  imports: [ClientTopMenuComponent, RouterOutlet],
  templateUrl: './overlay-client.component.html',
  styleUrl: './overlay-client.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayClientComponent {
}
