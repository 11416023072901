<div class="subdivision">
  <div class="subdivision_block">
    <div class="subdivision__back">
      <app-icon-button
        size="normal"
        type="square"
        icon="undo"
        display="overlay"
        color="cell"
        (click)="navigateToPrevious()">
      </app-icon-button>
    </div>
    <h1>Выберите подразделение</h1>
    <app-search
      class="top-menu__search-input"
      borderRadius="large"
      placeholder="Поиск"
      [control]="searchName"
      (changeEvent)="handleSearch()"
      (clearEvent)="handleClear()"></app-search>
    <div class="subdivision_block-list">
      @for (subdivision of subdivisions; track $index) {
        <div
          class="subdivision_block-list-element"
          (click)="handleSelectSubdivisionClick(subdivision)">
          {{ $index + 1 + '. ' + subdivision.name }}
          <span>({{ getSubdivisionTypeTranslation(subdivision) }})</span>
        </div>
      }
    </div>
  </div>
</div>
